import { inject, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { isNil } from 'lodash-es';
import { catchError, defer, EMPTY, Observable, pipe, retry, switchMap, tap } from 'rxjs';

import { Ad, ADS_AUTO_REFRESH_INTERVAL } from '@stsm/advertisement/models/ad';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { filterForValueTransition, filterUndefined, intervalWithActiveCheck, switchToVoid, VOID } from '@stsm/shared/util/rxjs.util';

import { AdsService, FetchAdsSource } from './ads.service';

interface AdsState {
  ads: Ad[];
  currentAdIndex: number;
  isVisible: boolean;
  shouldAutoRefreshAd: boolean;
  source: FetchAdsSource | undefined;
}

const initialState: AdsState = {
  ads: [],
  currentAdIndex: 0,
  isVisible: false,
  shouldAutoRefreshAd: false,
  source: undefined,
};

interface InitOptions {
  shouldAutoRefreshAd: boolean;
  source?: FetchAdsSource;
}

@Injectable()
export class AdsStore extends ComponentStore<AdsState> {
  readonly currentAd$: Observable<Ad | undefined>;

  readonly setIsVisible: (isVisible: boolean | Observable<boolean>) => void = this.updater((state: AdsState, isVisible: boolean) => ({
    ...state,
    isVisible,
  }));

  /**
   * Needs to be called to initialize fetching ads
   */
  readonly init: (options: InitOptions) => void = this.updater((state: AdsState, options: InitOptions) => ({
    ...state,
    shouldAutoRefreshAd: options.shouldAutoRefreshAd,
    source: options.source ?? 'default',
  }));

  /**
   * Used to manually proceed to the next ad after the current one has been used.
   * Should not be used in case the service was initialized with <code>shouldAutoRefreshAd: true</code>
   */
  readonly onDidShowAd: () => void = this.updater((state: AdsState) => {
    const { ads, currentAdIndex } = state;

    if (!isNil(ads[currentAdIndex + 1])) {
      return {
        ...state,
        currentAdIndex: currentAdIndex + 1,
      };
    }

    return state;
  });

  private readonly _appendAds: (ads: Ad[]) => void = this.updater((state: AdsState, ads: Ad[]) => ({
    ...state,
    ads: [...state.ads, ...ads],
  }));

  private readonly _isVisible$: Observable<boolean> = this.select((state: AdsState) => state.isVisible);
  private readonly _currentAdIndex$: Observable<number> = this.select((state: AdsState) => state.currentAdIndex);
  private readonly _ads$: Observable<Ad[]> = this.select((state: AdsState) => state.ads);
  private readonly _shouldAutoRefreshAd$: Observable<boolean> = this.select((state: AdsState) => state.shouldAutoRefreshAd);
  private readonly _source$: Observable<FetchAdsSource> = this.select((state: AdsState) => state.source).pipe(filterUndefined());

  private readonly _isNextAdAvailable$: Observable<boolean> = this.select(
    this._ads$,
    this._currentAdIndex$,
    (ads: Ad[], currentAdIndex: number) => !isNil(ads[currentAdIndex + 1]),
  );

  // Services
  private readonly _adsService: AdsService = inject(AdsService);
  private readonly _loggerService: LoggerService = inject(LoggerService);
  private readonly _environment: EnvironmentBase = inject(ENVIRONMENT);
  private readonly _browserStorageService: BrowserStorageService = inject(BrowserStorageService);

  constructor() {
    super(initialState);

    this.currentAd$ = this.select(this._ads$, this._currentAdIndex$, (ads: Ad[], currentAdIndex: number) => ads[currentAdIndex]);

    // Fetch more ads
    this.effect(
      pipe(
        filterForValueTransition({ from: true, to: false }),
        switchMap(() => this._fetchAds()),
      ),
    )(this._isNextAdAvailable$);

    // auto refresh ads
    this.effect(
      pipe(
        switchMap((shouldAutoRefreshAd: boolean) => {
          if (!shouldAutoRefreshAd) {
            return EMPTY;
          }

          return this._isNextAdAvailable$.pipe(
            switchMap((isNextAdAvailable: boolean) => {
              if (!isNextAdAvailable) {
                return EMPTY;
              }

              return intervalWithActiveCheck(this._adsAutoRefreshInterval, this._isVisible$).pipe(
                tap(() => {
                  this.patchState((state: AdsState) => ({ currentAdIndex: state.currentAdIndex + 1 }));
                }),
              );
            }),
          );
        }),
      ),
    )(this._shouldAutoRefreshAd$);

    // initial ad fetch
    this.effect(pipe(switchMap(() => this._fetchAds())))(this._source$);
  }

  private _fetchAds(): Observable<void> {
    return defer(() => this._adsService.getAds({ source: this.state().source })).pipe(
      retry({ count: 1, delay: 2_000, resetOnSuccess: true }),
      tap((ads: Ad[]) => this._appendAds(ads)),
      switchToVoid(),
      catchError((error: unknown) => {
        this._loggerService.warn(error);

        return VOID;
      }),
    );
  }

  private get _adsAutoRefreshInterval(): number {
    if (this._environment.name === 'E2E') {
      return (
        this._browserStorageService.getItemLocalStorage(GlobalLocalStorageKey.ADS_AUTO_REFRESH_INTERVAL_E2E) ?? ADS_AUTO_REFRESH_INTERVAL
      );
    }

    return ADS_AUTO_REFRESH_INTERVAL;
  }
}
